import { apiUrl } from '../../utils/api';
import { getDate } from '../../utils/dateHelper';
import { IMap, IMapList } from '../../models/map';
import { supportedFileTypes } from '../../models/file';
import { IMapArrangedByGroup, MapGroup } from '../../models/tickets';
import { getReqdElasticDataFields } from '../../utils/couchdb-elasticHelper';
import { sendPutRequest, sendPostRequest, sendGetRequest } from '../../utils/requestController';
import { generateOperations, generateContent, generateDate, generatePlatformData, trimLongText, setStatusObj } from '../../utils/utils';

const deleteFile = (listItem, confirm, database, lang, appState) => {
  const data = { id: listItem, platform: generatePlatformData(appState) };
  const url = `${apiUrl.v2api}file/${database}/softDelete`;
  return new Promise(resolve => {
    confirm({
      catchOnCancel: false,
      title: 'Ed Controls',
      description: [lang.m_warn_library_delete],
    }).then(() => {
      sendPutRequest(url, data).then(() => {
        return resolve(true); // Send true since response will be empty
      });
    });
  });
};

const deleteFileGroup = (confirm, lang, appState, database, groupId) => {
  const data = { id: [groupId], platform: generatePlatformData(appState) };
  const url = `${apiUrl.v2api}fileGroup/${database}/softDelete`;
  return new Promise(resolve => {
    confirm({
      catchOnCancel: false,
      title: 'Ed Controls',
      description: [lang.m_warn_group_delete],
    }).then(() => {
      sendPutRequest(url, data).then(() => {
        return resolve(true);
      });
    });
  });
};

const archiveMapsAndTickets = (resolve, reject, appState, projectId, listItem) => {
  const currentDate = getDate('now');
  listItem.forEach(async id => {
    const mapData: any = await getSpecificMap(projectId, id);
    /** Archive the map and bulk archive the tickets present in the map */
    mapData.archived = currentDate;
    // Update date and content if present
    if (mapData.dates && mapData.dates.lastModifiedDate) mapData.dates.lastModifiedDate = getDate('now');
    if (mapData.content && mapData.content.lastModifier) mapData.content.lastModifier = appState.get('id', 'user');
    let url = `${apiUrl.couchDBUrl}${projectId}/${mapData._id}?dummy= ${new Date().getTime()}`;
    sendPutRequest(url, mapData).then(() => {
      let url = `${apiUrl.v2api}tickets?size=99999&page=0&database=${projectId}&mapId=${mapData._id}`;
      sendGetRequest(url).then(async response => {
        let req_info: any = {},
          info_array: any = [],
          doc_id: any = [];
        let ticket_info = response.results;
        req_info.archive_ticket = true;
        req_info.archived = currentDate;
        req_info.dates = {};
        req_info.dates.lastModifiedDate = currentDate;
        req_info.operation = {};
        ticket_info.forEach(ticket => {
          doc_id.push(ticket.couchDbId);
          //as we are updating last modified in dates object we have to fill old creation date into date object
          req_info.dates.creationDate = ticket.dates.creationDate;
          /** Add operation to archive tickets */
          req_info.operation[ticket.couchDbId] = generateOperations(appState, 'updated', ['archived'], ['' + currentDate], ['']);
        });
        info_array.push(req_info);
        if (doc_id.length > 0) {
          let bulkData: any = await getBulkDoc(doc_id, projectId);
          let data_update: any = [];
          bulkData.rows.forEach((_info, index) => {
            // REFACTOR: to be moved to utils
            info_array.forEach(info => {
              Object.keys(info).forEach(function (key) {
                let value = info[key];

                let val: any = value;
                if (key === 'archive_ticket') {
                  return;
                }
                if (key === 'import_template') {
                  //this will delete the id and rev when imported
                  delete bulkData.rows[index].doc._id;
                  delete bulkData.rows[index].doc._rev;
                  delete bulkData.rows[index].doc.signature;
                  delete bulkData.rows[index].doc.status;
                  if (!bulkData.rows[index].doc.auditType) {
                    bulkData.rows[index].doc.auditType = 'area';
                  }
                  bulkData.rows[index].doc.timeline = [];
                } else if (key === 'timeline') {
                  if (bulkData.rows[index].doc[key]) {
                    bulkData.rows[index].doc[key] = bulkData.rows[index].doc[key].concat(val);
                  } else {
                    bulkData.rows[index].doc[key] = {};
                    bulkData.rows[index].doc[key] = val;
                  }
                } else if (key === 'operation') {
                  if (!bulkData.rows[index].doc.operation) {
                    bulkData.rows[index].doc.operation = [];
                  }
                  if (info.archive_ticket) {
                    bulkData.rows[index].doc.operation.push(val[bulkData.rows[index].doc._id]);
                    return;
                  }
                  if (bulkData.rows[index].doc[key]) {
                    bulkData.rows[index].doc[key] = bulkData.rows[index].doc[key].concat(val);
                  } else {
                    bulkData.rows[index].doc[key] = {};
                    bulkData.rows[index].doc[key] = val;
                  }
                } else if (key === 'lastModifiedDate') {
                  if (bulkData.rows[index].doc.dates) {
                    bulkData.rows[index].doc.dates.lastModifiedDate = val;
                  } else {
                    bulkData.rows[index].doc.dates = {};
                    bulkData.rows[index].doc.dates.lastModifiedDate = val;
                  }
                } else {
                  bulkData.rows[index].doc[key] = val;
                }
              });
              data_update.push(bulkData.rows[index].doc);
            });
          });
          let docs: any = {};
          docs.docs = data_update;
          url = apiUrl.couchDBUrl + projectId + '/_bulk_docs';
          sendPostRequest(url, docs)
            .then(() => {
              return resolve(true);
            })
            .catch(err => reject(err));
        } else {
          return resolve(true);
        }
      });
    });
  });
};

const archiveFile = async (listItem, isFileList, isActive, projectId, appState?) => {
  const data = { id: listItem, platform: generatePlatformData(appState) };
  if (isFileList) {
    let url = `${apiUrl.v2api}file/${projectId}/archive?archive=${!isActive}`;
    return new Promise((resolve, reject) => {
      sendPutRequest(url, data).then(() => {
        if (!isActive) {
          url = `${apiUrl.v2api}map/${projectId}/getMapsOfFile`;
          sendPostRequest(url, listItem).then(list => {
            if (list.length > 0) {
              archiveMapsAndTickets(resolve, reject, appState, projectId, list);
              return resolve(true);
            } else return resolve(true);
          });
        } else {
          return resolve(true);
        }
      });
    });
  } else {
    if (!isActive) {
      // Archive the maps
      return new Promise((resolve, reject) => {
        archiveMapsAndTickets(resolve, reject, appState, projectId, listItem);
      });
    } else {
      // De archive the maps
      /** De-archive the map */
      return new Promise(resolve => {
        listItem.forEach(async id => {
          const mapData: any = await getSpecificMap(projectId, id);
          mapData.archived = null;
          // Update date and content if present
          if (mapData.dates && mapData.dates.lastModifiedDate) mapData.dates.lastModifiedDate = getDate('now');
          if (mapData.content && mapData.content.lastModifier) mapData.content.lastModifier = appState.get('id', 'user');
          let url = `${apiUrl.couchDBUrl}${projectId}/${mapData._id}?dummy= ${new Date().getTime()}`;
          sendPutRequest(url, mapData).then(() => {
            return resolve(true);
          });
        });
      });
    }
  }
};

const replaceItem = (uploadedFile, fileData, appState, databaseId) => {
  return new Promise(resolve => {
    fileData.operation.push(generateOperations(appState, 'updated', ['File Replaced'], [uploadedFile.filename], ['']));
    fileData.content = generateContent(fileData.content, appState);
    fileData.dates = generateDate(fileData.dates, appState);
    fileData.size = uploadedFile.size;
    const url = `${apiUrl.v2api}file/${databaseId}/${fileData._id}?fileUrl=${encodeURIComponent(uploadedFile.url)}&uploadedName=${encodeURIComponent(uploadedFile.filename)}`;
    sendPutRequest(url, fileData).then((data: any) => {
      return resolve(data);
    });
  });
};

const tileFile = async (file, appState, selectedGroup, replace: boolean | undefined, confirm, lang, databaseId, actionType, socket, mapId) => {
  const copy = await copyTags(confirm, file, replace, lang);
  let doc: any = {};
  doc = constructTileJob(appState, file, databaseId, selectedGroup, actionType, socket, mapId);
  if (copy) {
    doc.tags = file.tags;
  }
  return new Promise(resolve => {
    sendPostRequest(apiUrl.v2api + 'tiler/' + databaseId + '/' + file.couchDbId + '/tileDocument?versionId=' + file.versionId, JSON.stringify(doc)).then((data: any) => {
      return resolve(data);
    });
  });
};
/** A generic method to construct Tile Job */
const constructTileJob = (appState, file, databaseId, selectedGroup, type = '', socket, mapId) => {
  let doc: any = {};
  const currentDate = getDate('now');
  let uniqueid = new Date().getTime() + (appState.get('allDb.active.id', 'projects') || '');
  let storeChannelId = appState.get('channelIds', 'projects') || [];
  storeChannelId.push(uniqueid);
  appState.set('channelIds', storeChannelId, 'projects');
  if (type === 'replace' || type === 'restore') {
    let updateChannel = appState.get('mapUpdateChannelIds', 'projects') || [];
    updateChannel.push(uniqueid);
    appState.set('mapUpdateChannelIds', updateChannel, 'projects');
  }
  let sendStatusObj = {
    channelID: uniqueid,
    status: 1,
    time: new Date().toTimeString().split(' ')[0],
    filename: file.fileName,
    type: 'tile',
    isShown: true,
  };

  socket.emit('new', { channelID: uniqueid });
  setStatusObj(sendStatusObj, appState);
  doc.sendStatus = {
    channelId: uniqueid,
    time: new Date().toTimeString().split(' ')[0],
    fileName: file.fileName,
  };
  doc.mapId = mapId || '';
  doc.fileStackUrl = null;
  if (file.sourceName) doc.sourceName = file.sourceName;
  doc.headers = {
    from: appState.get('id', 'user'),
    to: databaseId + '@edcontrols.nl',
    subject: selectedGroup!.name,
    date: currentDate,
  };
  doc.readyForTiler = true;
  doc.platform = generatePlatformData(appState);
  return doc;
};

const copyTags = async (confirm, file, replace, lang) => {
  return new Promise(resolve => {
    if (replace && file.tags?.length > 0) {
      confirm({
        catchOnCancel: true,
        title: lang.m_txt_copy_tags_para_1,
        cancelLabel: lang.m_lbl_no,
        successLabel: lang.m_lbl_yes,
        description: [lang.m_txt_copy_tags_para_2],
      }).then(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        },
      );
    } else {
      resolve(false);
    }
  });
};

const addRemoveFileTag = async (fileData, appState, tagList, databaseId) => {
  fileData.operation.push(generateOperations(appState, 'updated', ['tags'], [tagList], [fileData.tags ? fileData.tags : null]));
  fileData.content = generateContent(fileData.content, appState);
  fileData.dates = generateDate(fileData.dates, appState);
  fileData.tags = tagList;
  return new Promise(resolve => {
    sendPutRequest(apiUrl.v2api + 'file/' + databaseId + '/' + fileData._id, fileData).then(() => {
      resolve(true);
    });
  });
};

const getSpecificMap = (databseId, id) => {
  const url = apiUrl.v2api + 'maps/' + databseId + '/' + id;
  return new Promise(resolve => {
    sendGetRequest(url).then(mapDocument => {
      resolve(mapDocument);
    });
  });
};

const getMapByFileById = (fileId, databseId) => {
  const url = apiUrl.v2api + 'map/' + databseId + '/getMapsByFileId?fileId=' + fileId;
  return new Promise(resolve => {
    sendGetRequest(url).then(map => {
      resolve(map);
    });
  });
};

const getBulkDoc = (id, db) => {
  const url = apiUrl.couchDBUrl + db + '/_all_docs?include_docs=true';
  return new Promise(resolve => {
    let post_Data: any = {};
    post_Data.keys = id;
    sendPostRequest(url, post_Data).then(response => {
      resolve(response);
    });
  });
};

const updateFileNameAPI = (data, databseId) => {
  const url = `${apiUrl.v2api}file/${databseId}/updateFileName`;
  return new Promise(resolve => {
    sendPutRequest(url, data).then(response => {
      resolve(response);
    });
  });
};

const getMapsforResponsible = (url, data) => {
  return new Promise(resolve => {
    sendPostRequest(url, data).then(response => {
      resolve(response);
    });
  });
};

const getSpecificGroup = async (databaseId, fileGroupID) => {
  const url = `${apiUrl.v2api}fileGroup/${databaseId}/${fileGroupID}`;
  return new Promise(resolve => {
    sendGetRequest(url).then(response => {
      return resolve(response);
    });
  });
};

const getTagSuggestion = async databaseId => {
  const url = `${apiUrl.v2api}project/getTags`;
  const body = {
    projects: [databaseId],
  };
  return new Promise<String[]>(resolve => {
    sendPostRequest(url, body).then(response => {
      return resolve(response);
    });
  });
};

const fileUploadService = async (fileObj, documentItem, confirm, lang) => {
  let file = fileObj[0];
  const fileName = file.filename.split('.').slice(0, -1).join('.');
  // (TOBEREMOVED) Temp fix as file stack is not giving content type for insp
  let fileExtension = '';
  fileExtension = file.filename.split('.').pop() || '';
  fileExtension = fileExtension.toLowerCase();
  let documentExtension = '';
  documentExtension = documentItem.fileName.split('.').pop() || '';
  documentExtension = documentExtension.toLowerCase();
  if (fileExtension === 'insp') {
    file = new File([file], file.filename, { type: 'image/jpeg' });
  }
  return new Promise(resolve => {
    // Moved to extension from content type to support insp file
    if (fileExtension === documentExtension) {
      // Replace file if same extension
      return resolve('replace');
    } else if (fileName.trim() === '' || fileName.charAt(0) === '_' || fileName.charAt(fileName.length - 1) === '_') {
      // Check if Supported file type
      confirm({
        catchOnCancel: false,
        title: 'Ed Controls',
        description: [lang.m_wrn_file_name],
        successLabel: lang.m_btn_ok,
        hideCancel: true,
      }).then(() => {
        return resolve('error');
      });
    } else if (supportedFileTypes.indexOf(file.mimetype) === -1) {
      // Check if Supported file type
      confirm({
        catchOnCancel: false,
        title: 'Ed Controls',
        description: [lang.m_wrn_library_file, lang.m_wrn_file_hint],
        successLabel: lang.m_txt_upload,
      }).then(() => {
        return resolve('unsupportedFile');
      });
    } else {
      // If Supported but not same as replaced file type
      confirm({
        catchOnCancel: false,
        title: lang.m_err_replace_file_para_1,
        description: [lang.m_err_replace_file_para_2, lang.m_err_replace_file_para_3],
        successLabel: lang.m_txt_upload,
      }).then(() => {
        return resolve('newUpload');
      });
    }
  });
};

/* Get 2 different set of Arrays of id (Mao and file)  */
const separateFileMapIds = async dataSet => {
  return new Promise(resolve => {
    const result = dataSet.reduce(
      (output, data) => {
        if (data.type === 'IB.EdBundle.Document.File') output[0].push(data.couchDbId);
        else if (data.type === 'IB.EdBundle.Document.Map') output[1].push(data.couchDbId);
        return output;
      },
      [[], []],
    );
    return resolve(result);
  });
};

/** Get map by id */
const getMapById = (id: string, database: string) => {
  const url = `${apiUrl.couchDBUrl}${database}/${id}?dummy=${new Date().getTime()}`;
  return new Promise<IMap>(resolve => {
    sendGetRequest(url).then(response => {
      resolve(response);
    });
  });
};
/**
 * @function getSignedLink()
 * @description Function to get the Public accessible File URL for Front end View via API
 */
const getSignedUrl = (databaseId: string, fileId: string, version: string, fileName) => {
  const url = `${apiUrl.v2api}file/${databaseId}/${fileId}/${version}/${encodeURIComponent(fileName)}/generateSignedLink`;
  return new Promise(resolve => {
    sendGetRequest(url).then(response => {
      resolve(response);
    });
  });
};
/** Get all maps archived or unarchived */
const getMapList = async (databaseId: string, archived: boolean, includeType: string, isReporter: boolean = false) => {
  const url = `${apiUrl.v2api}maps/search?size=9999&database=${databaseId}`;
  const body = {
    archived,
    sortby: 'group',
    sortOrder: 'asc',
    includeFields: getReqdElasticDataFields(includeType),
  };
  if (isReporter) {
    body['isReporter'] = true;
  }
  return new Promise<IMapList>(resolve => {
    sendPostRequest(url, body).then(data => {
      return resolve(data);
    });
  });
};

/** Arrange map list according to its group */
const getGroupBasedMaps = mapList => {
  let groupData: IMapArrangedByGroup = {};
  return new Promise<IMapArrangedByGroup>(resolve => {
    mapList.forEach(map => {
      if (!groupData[map.group]) {
        groupData[map.group] = new MapGroup(map);
      } else {
        groupData[map.group].maps.push(map);
      }
    });
    resolve(groupData);
  });
};

const sortTags = tagList => {
  return tagList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};

const showChangedTags = (oldValue, newValue) => {
  if (typeof oldValue == 'string') {
    const oldData = JSON.parse(oldValue);
    let newData = JSON.parse(newValue);
    return newData.filter(x => !oldData.includes(x));
  } else if (typeof oldValue == 'object') {
    return newValue.filter(x => !oldValue.includes(x));
  }
};

const generateTimelineHistory = (data, fileDetail, lang, isCurrent?) => {
  if (fileDetail.type === 'IB.EdBundle.Document.File') {
    if (data.changedProperties[1] === 'versionId' && data.changedProperties[0] !== 'fileName') {
      return `${fileDetail.versions[0].fileName}`;
    } else {
      switch (data.changedProperties[0]) {
        case 'versionId':
          let fileNameShown = lang.m_lbl_unknown_file;
          const index = fileDetail.versions.findIndex(version => data.newValues[0] === version.id);
          if (index === 0) {
            fileNameShown = fileDetail.fileName;
          } else if (index > 0) {
            fileNameShown = fileDetail.versions[index].uploadedName ? fileDetail.versions[index].uploadedName : lang.m_lbl_unknown_file;
          }
          if (data.newValues[1] === 'replaced') {
            if (fileDetail.versions[index].id === fileDetail.versionId) {
              if (isCurrent === 'current-version') {
                return ` ${trimLongText(fileNameShown, 40)}`;
              } else {
                return ` ${fileNameShown}`;
              }
            }
            return ` ${fileNameShown}`;
          }
          if (fileDetail?.versions[index]?.uploadedName) {
            return `${fileDetail.versions[index].uploadedName}`;
          } else {
            return `${lang.m_lbl_unknown_file}`;
          }
        case 'tags':
          if (data.oldValues[0] !== 'null' && data.oldValues[0] !== null && data.newValues[0].length > data.oldValues[0].length) {
            return `${lang.m_lbl_added_tag}: ${showChangedTags(data.oldValues[0], data.newValues[0])}`;
          } else if (data.oldValues[0] === 'null' || data.oldValues[0] === null) {
            return `${lang.m_lbl_added_tag}: ${data.newValues[0]}`;
          } else if (data.newValues[0] !== '[]' && data.newValues[0].length < data.oldValues[0].length) {
            return `${lang.m_lbl_removed_tag}: ${showChangedTags(data.newValues[0], data.oldValues[0])}`;
          } else if (data.newValues[0] === '[]') {
            return `${lang.m_lbl_removed_tag}: ${data.oldValues[0]}`;
          } else if (data.newValues[0].length === data.oldValues[0].length) {
            let removedTags = data.oldValues[0].filter(x => {
              return !data.newValues[0].includes(x);
            });
            let addedTags = data.newValues[0].filter(x => {
              return !data.oldValues[0].includes(x);
            });

            if (addedTags.length > 0 && removedTags.length > 0) {
              return `${lang.m_lbl_added_tag}: ${addedTags.join(' ; ')}  ${lang.m_lbl_removed_tag}: ${removedTags.join(' ; ')}`;
            } else if (addedTags.length > 0) {
              return `${lang.m_lbl_added_tag}: ${addedTags.join(' ; ')}`;
            } else if (removedTags.length > 0) {
              return `${lang.m_lbl_removed_tag}: ${removedTags.join(' ; ')}`;
            }
            return;
          }
          break;
        case 'archived':
          if (data.newValues[0]) {
            return `${lang.m_lbl_archive_file}`;
          } else {
            return `${lang.m_lbl_dearchive_file}`;
          }
        case 'tile':
          return `${lang.m_lbl_convert_file_drawing}`;
        case 'tileFailed':
        case 'mapFailedConvert':
          return lang.m_lbl_fail_convert_drawing;
        case 'deleted':
          return `${lang.m_file_delete}`;
        case 'fileName':
          return lang.m_lbl_filename_timeline.replace('__OLD__', `"${data.oldValues[0]}"`).replace('__NEW__', `"${data.newValues[0]}"`);
        default:
          break;
      }
    }
  }
};
const getAllGroupList = (databaseId: string) => {
  const url = apiUrl.v2api + 'fileGroup/' + databaseId + '?size=99999';
  return new Promise<any>(resolve => {
    sendGetRequest(url).then(response => {
      const result = response.results.filter(group => !group.deleted);
      resolve(result);
    });
  });
};
const updateFileGroup = (data, databaseId: string) => {
  const url = apiUrl.v2api + 'fileGroup/' + databaseId;
  return new Promise<any>(resolve => {
    sendPostRequest(url, data).then(response => {
      resolve(response);
    });
  });
};
const updateMapGroup = (data, databaseId: string) => {
  const url = apiUrl.v2api + 'mapGroup/' + databaseId;
  return new Promise<any>(resolve => {
    sendPostRequest(url, data).then(response => {
      resolve(response);
    });
  });
};
const getAllowedFileContentType = () => {
  const url = `${apiUrl.v2api}allowedFileConfig`;
  return new Promise<any>(resolve => {
    sendGetRequest(url).then(response => {
      resolve(response.extensions);
    });
  });
};
const getFileDetail = (databaseId: string, mapId: string) => {
  const url = apiUrl.v2api + 'file/' + databaseId + '/' + mapId;
  return new Promise<any>(resolve => {
    sendGetRequest(url).then(response => {
      resolve(response);
    });
  });
};

const saveNewGroup = (fileGroup, databaseId: string) => {
  const url = apiUrl.v2api + 'fileGroup/' + databaseId + '/' + fileGroup._id;
  return new Promise<any>(resolve => {
    sendPostRequest(url, fileGroup).then(response => {
      fileGroup.type = 'IB.EdBundle.Document.MapGroup';
      sendPostRequest(apiUrl.v2api + 'mapGroup/' + databaseId, fileGroup).then(() => {
        fileGroup._id = response.id;
        fileGroup._rev = response.rev;
        fileGroup.couchDbId = response.id;
        fileGroup.type = 'IB.EdBundle.Document.FileGroup';
        resolve(response.results);
      });
    });
  });
};

export {
  deleteFile,
  deleteFileGroup,
  archiveFile,
  replaceItem,
  tileFile,
  addRemoveFileTag,
  getSpecificMap,
  getSpecificGroup,
  getTagSuggestion,
  fileUploadService,
  separateFileMapIds,
  getMapByFileById,
  constructTileJob,
  getMapById,
  getSignedUrl,
  getMapList,
  getGroupBasedMaps,
  sortTags,
  updateFileNameAPI,
  getMapsforResponsible,
  generateTimelineHistory,
  getAllGroupList,
  saveNewGroup,
  updateFileGroup,
  updateMapGroup,
  getFileDetail,
  getAllowedFileContentType,
};
